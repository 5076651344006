import React from "react";

function SocialLinks() {
  return (
    <div style={{ marginTop: "20px" }}>
      <a
        href="https://twitter.com/aquafomoUW"
        target="_blank"
        rel="noopener noreferrer"
        style={{ marginRight: "10px", color: "white", textDecoration: "none" }}
      >
        Twitter
      </a>
      <a
        href="https://t.me/aquafomo"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "white", textDecoration: "none" }}
      >
        Telegram
      </a>
    </div>
  );
}

export default SocialLinks;
