// src/Spoiler.js
import React, { useState } from "react";

function Spoiler({ title, keywords, description }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <button onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? "Hide" : "Show"}
      </button>
      {isOpen && (
        <div>
          <h3>{title}</h3>
          <p>
            <strong>Keywords:</strong> {keywords}
          </p>
          <p>{description}</p>
        </div>
      )}
    </div>
  );
}

export default Spoiler;
