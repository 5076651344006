import "./App.css";
import React from "react";
import Spoiler from "./Spoiler.js";
import SocialLinks from "./SocialLinks";

function HeaderComponent() {
  return <h1 style={{ fontFamily: "Press Start 2P" }}>AQUAFOMO</h1>;
}

function SubHeaderComponent() {
  return <h2 style={{ fontFamily: "Press Start 2P" }}>$UNDERWATER</h2>;
}

// Update the ImageComponent to set the width and height attributes
function ImageComponent() {
  return (
    <img src="/aquafomo.jpeg" alt="Game Preview" width="400" height="400" />
  );
}

function TextComponent() {
  return (
    <div style={{ fontFamily: "Press Start 2P" }}>
      <p>Liquidity locked</p>
      <p>Tax 0/0</p>
      <p>Ownership renounced</p>
      <p>Game released</p>
    </div>
  );
}

function App() {
  return (
    <div
      className="App"
      style={{
        backgroundColor: "aqua",
        color: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <HeaderComponent />
      <SubHeaderComponent />
      <TextComponent />
      <SocialLinks />
      <ImageComponent />
      <Spoiler
        title="AQUAFOMO $UNDERWATER: Unveiling a New Era of DeFi Gaming"
        keywords="DeFi gaming, web3, LP staking, AQUAFOMO $UNDERWATER, blockchain innovation"
        description="Embark on a revolutionary gaming journey with AQUAFOMO $UNDERWATER, where the allure of DeFi gaming merges with captivating 3D worlds. Leverage web3 technology to safeguard your assets while delving into LP staking possibilities amidst exhilarating game plots. Elevate your gaming narrative with AQUAFOMO $UNDERWATER!"
      />
      <Spoiler
        title="AQUAFOMO $UNDERWATER: Where DeFi Gaming Transcends to 3D Realms"
        keywords="DeFi gaming, web3, LP staking, AQUAFOMO $UNDERWATER, blockchain evolution"
        description="Traverse into the uncharted with AQUAFOMO $UNDERWATER game, where DeFi gaming interfaces with immersive 3D adventures. Employ web3 technology to fortify your assets and discover LP staking prospects within enthralling game narratives. Amplify your gaming odyssey with AQUAFOMO $UNDERWATER!"
      />
      <Spoiler
        title="AQUAFOMO $UNDERWATER: Pioneering the Fusion of DeFi Gaming and 3D Exploration"
        keywords="DeFi gaming, web3, LP staking, AQUAFOMO $UNDERWATER, blockchain frontier"
        description="Propel into the next-gen gaming arena with AQUAFOMO $UNDERWATER, where DeFi gaming amalgamates with immersive 3D escapades. Harness web3 technology to shield your assets and unearth LP staking ventures amidst riveting game storylines. Redefine your gaming chronicle with AQUAFOMO $UNDERWATER!"
      />
      <Spoiler
        title="AQUAFOMO $UNDERWATER: The Convergence of DeFi Gaming and 3D Excursions"
        keywords="DeFi gaming, web3, LP staking, AQUAFOMO $UNDERWATER, blockchain venture"
        description="Venture forth into a groundbreaking gaming domain with AQUAFOMO $UNDERWATER, where DeFi gaming coincides with enthralling 3D journeys. Utilize web3 technology to protect your assets and explore LP staking opportunities in captivating game settings. Enhance your gaming saga with AQUAFOMO $UNDERWATER!"
      />
    </div>
  );
}

export default App;
